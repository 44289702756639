import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject, map, Observable, of, switchMap, combineLatest} from "rxjs";
import {BsCoreAuthenticatedService, BsCoreErrorService} from "@boekscout/bs-core";
import {Router} from "@angular/router";
import {BsSharedDialogService, BsSharedMenuItemModel} from "@boekscout/bs-shared";
import {BsDataAuthorModel, BsDataAuthorService} from "@boekscout/bs-data";
import {BsManuscriptContactAppointmentService, BsManuscriptDossierService} from "@boekscout/bs-manuscript";

@Component({
    selector: 'author-root',
    template: `
        <ng-template #errorDialog let-error="error">
            <bs-shared-part-marginal>
                Helaas gaat er iets mis
            </bs-shared-part-marginal>
            <bs-shared-part-body class="tone-warning">
                @switch (error.code) {
                    @case ('authentication') {
                        <p>
                            Je authenticatie lijkt verlopen te zijn.
                        </p>
                        <p class="mb-0">
                            <a
                                    routerLink="/sign-out"
                                    class="link"
                            >
                                Opnieuw inloggen
                            </a>
                        </p>
                    }
                    @case ('authorization') {
                        <p class="text-center">
                            Het lijkt er op dat je iets probeert te doen waar je geen rechten voor hebt.
                        </p>
                    }
                    @case ('tooManyRequests') {
                        <p class="text-center">
                            Te veel verzoeken in periode ontvangen, probeer het later opnieuw
                        </p>
                    }
                    @case ('server') {
                        <p class="text-center">
                            Er lijkt iets mis te gaan aan de server kant. Probeer het later nog eens, neem anders contact op met info&#64;boekscout.nl
                        </p>
                    }
                    @default {
                        <p>
                            Er lijkt iets mis te gaan. Probeer het later nog eens, neem anders contact op met info&#64;boekscout.nl
                        </p>
                        <p>
                            <strong>Error code:</strong> {{ error.code }}
                        </p>
                    }
                }
            </bs-shared-part-body>
        </ng-template>
        <bs-shared-dialog-stack/>
        @if (authenticated$ | async) {
            <div class="layout-authenticated">
                <a
                        [ngClass]="{'tone-warning': env.development}"
                        [routerLink]="['/']"
                        class="logo"
                >
                    <img
                            src="/assets/images/logo/bs-basic-light.png"
                            alt="Boekscout"
                            class="theme-dark-show"
                    >
                    <img
                            src="/assets/images/logo/bs-basic-dark.png"
                            alt="Boekscout"
                            class="theme-light-show"
                    >
                </a>
                <div class="bar">
                    <button
                            (click)="showNavigation$.next(!showNavigation$.getValue())"
                            class="toggle-navigation tone-secondary"
                            type="button"
                    >
                        {{ 'shared.noun.menu' | translate }}
                    </button>
                </div>
                <nav
                        [ngClass]="{'show': showNavigation$ | async}"
                        class="navigation"
                >
                    @if (menu$ | async; as menu) {
                        <bs-shared-menu
                                class="sidebar-menu"
                                [items]="menu"
                        />
                    }
                </nav>
                <main class="content">
                    <div>
                        <router-outlet></router-outlet>
                    </div>
                </main>
            </div>
        } @else {
            <router-outlet></router-outlet>
        }
    `,
    styles: [`
      .layout-authenticated {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "logo bar"
          "navigation navigation"
          "content content";
      }
      
      .navigation:not(.show) {
        transition: max-height 0.25s ease-out;
        max-height: 0;
      }
      
      .navigation {
        transition: max-height 0.5s ease-in;
        max-height: 1500px;
        overflow: hidden;
      }
      
      .logo, .bar, .navigation {
        background-color: hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 5 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
      }

      .logo {
        padding: calc(var(--padding) / 2) var(--padding);
        align-items: center;
        grid-area: logo;
        display: flex;
      }
      
      .logo img {
        max-height: 35px;
      }

      .bar {
        padding: calc(var(--padding) / 2) var(--padding);
        justify-content: flex-end;
        align-items: center;
        grid-area: bar;
        display: flex;
      }

      .navigation {
        grid-area: navigation;
      }

      .content {
        grid-area: content;
      }

      .content {
        padding: var(--padding);
      }
      
      @media screen and (min-width: 960px) {
        .layout-authenticated {
          grid-template-columns: 16rem 1fr;
          grid-template-areas: "logo bar"
            "navigation content";
          grid-template-rows: auto 1fr;
          min-height: 100vh;
        }
        
        .logo, .bar {
          border-bottom: 1px solid hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 25 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
        }
        
        .logo {
          justify-content: center;
          display: flex;
          position: sticky;
          top: 0;
        }
        
        .navigation {
          border-right: 1px solid hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 25 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
          max-height: initial !important;
          overflow: initial;
        }
        
        .sidebar-menu {
          position: sticky;
          top: 3rem;
        }
        
        .content > * {
          max-width: 80rem;
          display: block;
          margin: 0 auto;
        }
        
        .toggle-navigation {
          display: none;
        }
      }
    `],
    standalone: false
})
export class AppComponent implements OnInit {
    @ViewChild('errorDialog') errorDialog: TemplateRef<any>;

    authenticated$: Observable<boolean>;

    showNavigation$ = new BehaviorSubject(false);

    menu$: Observable<BsSharedMenuItemModel[]>;

    constructor(
        @Inject('env') public env: { development: boolean },
        private manuscriptContactAppointmentService: BsManuscriptContactAppointmentService,
        private manuscriptDossierService: BsManuscriptDossierService,
        private authenticatedService: BsCoreAuthenticatedService,
        private dialogService: BsSharedDialogService,
        private authorService: BsDataAuthorService,
        private translateService: TranslateService,
        private errorService: BsCoreErrorService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.translateService.setDefaultLang('nl_NL');

        this.authenticated$ = this.authenticatedService.isAuthenticated();

        this.menu$ = this
            .authenticated$
            .pipe(
                switchMap(() => this.authorService.me()),
                switchMap(response => this.getMenu(response.result)),
            );

        this
            .errorService
            .get()
            .subscribe((error) => {
                const onClose = this.dialogService.open(this.errorDialog, { error });
                onClose.subscribe(() => this.errorService.clear());
            });

        this.router.events.subscribe(() => {
            this.showNavigation$.next(false);
        })
    }

    private getMenu(author: BsDataAuthorModel): Observable<BsSharedMenuItemModel[]> {
        return combineLatest(
            {
                activeManuscriptDossier: this
                    .manuscriptDossierService
                    .getForMe()
                    .pipe(
                        map(response => {
                            for (let dossier of response.results) {
                                if (dossier.meta.closed || dossier.meta.contract.received || dossier.meta.hold) {
                                    continue;
                                }

                                return dossier;
                            }

                            return null;
                        })
                    ),
            }
        )
            .pipe(
                switchMap((response) => {
                    return combineLatest(
                        {
                            activeManuscriptDossier: of(response.activeManuscriptDossier),
                            contactAppointments: response.activeManuscriptDossier
                                ? this
                                    .manuscriptContactAppointmentService
                                    .getWithDossier({ type: response.activeManuscriptDossier.type, id: response.activeManuscriptDossier.id })
                                    .pipe(map(response => response.results))
                                : of(null),
                        },
                    );
                }),
                map(response => {
                    const menu: BsSharedMenuItemModel[] = [
                        {
                            title: 'Home',
                            route: ['/'],
                            type: 'route',
                            exact: true,
                            icon: 'home',
                        },
                        {
                            title: 'Profiel',
                            route: ['/me/profile'],
                            type: 'route',
                            icon: 'user',
                            exact: true,
                            subs: [
                                {
                                    title: 'Social media',
                                    route: ['/me/profile/social'],
                                    type: 'route',
                                },
                                {
                                    title: 'shared.noun.security',
                                    route: ['/me/profile/security'],
                                    type: 'route',
                                },
                            ],
                        },
                    ];

                    const manuSubMenus: BsSharedMenuItemModel[] = [
                        {
                            route: ['/manuscript/process'],
                            title: 'Proces',
                            type: 'route',
                        },
                    ];

                    if (response.activeManuscriptDossier?.attributes.expectations === null) {
                        manuSubMenus.push({
                            route: ['/manuscript/questionnaire'],
                            title: 'Vragenlijst',
                            type: 'route',
                        });
                    }

                    if (response.contactAppointments) {
                        manuSubMenus.push({
                            route: ['/manuscript/contact-appointment'],
                            title: 'Belafspraak',
                            type: 'route',
                        });
                    }

                    menu.push({
                        title: 'Manuscripten',
                        icon: 'book',
                        type: 'toggle',
                        subs: [
                            ...manuSubMenus,
                            {
                                route: ['/manuscript/judgements'],
                                title: 'shared.noun.judgements',
                                type: 'route',
                            },
                            {
                                route: ['/manuscript/book-details'],
                                title: 'Boekgegevens',
                                type: 'route',
                                subs: [
                                    {
                                        route: ['/manuscript/book-details/information'],
                                        title: 'shared.noun.information',
                                        type: 'route',
                                    },
                                ],
                            },
                            {
                                route: ['/manuscript/definitive-manuscript'],
                                title: 'Definitieve manuscript',
                                type: 'route',
                            }
                        ],
                    },);

                    if (author.meta.books.total > 0) {
                        menu
                            .push(
                                {
                                    title: 'shared.noun.production',
                                    icon: 'list-check',
                                    type: 'toggle',
                                    subs: [
                                        {
                                            route: ['/production/process'],
                                            title: 'Proces',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/production/deadline'],
                                            title: 'Deadlines',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/production/imagery'],
                                            title: 'Beeldmateriaal',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/production/design-wishes'],
                                            title: 'Wensen vormgeving',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/production/promotion-mailing'],
                                            title: 'Promotiemailing',
                                            type: 'route',
                                        },
                                    ],
                                },
                                {
                                    route: ['/administration/phone-appointment'],
                                    title: 'Telefonische afspraak',
                                    icon: 'phone',
                                    type: 'route',
                                },
                                {
                                    title: 'Promotiemiddelen',
                                    icon: 'bullhorn',
                                    type: 'toggle',
                                    subs: [
                                        {
                                            route: ['/marketing/promotion-material'],
                                            title: 'Promotiemateriaal',
                                            type: 'route',
                                            exact: true,
                                        },
                                        {
                                            route: ['/marketing/promotion-material/social-media'],
                                            title: 'Social Media',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/marketing/qr-code'],
                                            title: 'QR code',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/marketing/sales-tools'],
                                            title: 'Verkooptools',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/marketing/press-release'],
                                            title: 'shared.noun.pressRelease',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/marketing/preview'],
                                            title: 'Inkijk',
                                            type: 'route',
                                        },
                                    ],
                                },
                                {
                                    route: ['/marketing'],
                                    title: 'Marketing',
                                    type: 'route',
                                    icon: 'globe',
                                    exact: true,
                                    subs: [
                                        {
                                            route: ['/marketing/questionnaire'],
                                            title: 'Vragenlijst',
                                            type: 'route'
                                        },
                                        {
                                            route: ['/marketing/practical-information'],
                                            title: 'Praktische informatie',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/marketing/user-guides'],
                                            title: 'Informatiefolders',
                                            type: 'route'
                                        },
                                        {
                                            route: ['/marketing/media/manage'],
                                            title: 'In de media',
                                            type: 'route',
                                        },
                                        {
                                            title: 'Auteurs in de media',
                                            route: ['/marketing/media'],
                                            exact: true,
                                            type: 'route',
                                        },
                                        {
                                            title: 'Boekrecensies',
                                            route: ['/marketing/book-commentary'],
                                            exact: true,
                                            type: 'route',
                                        },
                                        {
                                            title: 'Content kalender',
                                            route: ['/marketing/content-calendar'],
                                            type: 'route',
                                        },
                                        {
                                            title: 'Koninklijke Bibliotheek',
                                            route: ['/marketing/library'],
                                            type: 'route',
                                        },
                                    ],
                                },
                                {
                                    route: ['/author-blog'],
                                    title: 'Auteursblog',
                                    type: 'route',
                                    icon: 'rss',
                                },
                                {
                                    route: ['/administration/royalty'],
                                    title: 'shared.noun.royalties',
                                    icon: 'chart-pie',
                                    type: 'route',
                                    exact: true,
                                    subs: [
                                        {
                                            route: ['/administration/royalty/transactions'],
                                            title: 'shared.noun.transactions',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/administration/royalty/per-book'],
                                            title: 'shared.noun.sales',
                                            type: 'route',
                                        },
                                        {
                                            route: ['/administration/royalty/pay-outs'],
                                            title: 'shared.noun.payOuts',
                                            type: 'route',
                                        },
                                    ],
                                },
                                {
                                    route: ['/administration/order'],
                                    title: 'Mijn bestellingen',
                                    icon: 'shipping',
                                    type: 'route',
                                    exact: true,
                                },
                                {
                                    route: ['/administration/order/start'],
                                    icon: 'shopping-cart',
                                    title: 'Bestel je boek',
                                    type: 'route',
                                },
                            );
                    }

                    menu.push(
                        {
                            title: 'Uitloggen',
                            route: ['/me/sign-out'],
                            icon: 'sign-out',
                            type: 'route',
                        },
                    )

                    return menu;
                }),
            );
    }
}
