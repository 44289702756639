import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import en from '@angular/common/locales/en';
import nl from '@angular/common/locales/nl';
import {AppComponent} from './components/app.component';
import {RouterModule} from "@angular/router";
import {appRoutes} from "./app.routes";
import {registerLocaleData} from "@angular/common";
import {FakeMissingTranslationHandler, MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {BsCoreAuthenticatedGuard, BsCoreGuestGuard, BsCoreRpcService} from "@boekscout/bs-core";
import {environment} from '../environments/environment';
import {BsSharedDialogStackComponent, BsSharedMenuComponent, BsSharedPartBodyComponent, BsSharedPartMarginalComponent} from "@boekscout/bs-shared";
import {ErrorInterceptor} from "./interceptor/error-interceptor.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        './assets/i18n/',
        `.json`
    );
}

registerLocaleData(nl);
registerLocaleData(en);

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,

        RouterModule.forRoot(appRoutes, {
            anchorScrolling: 'enabled',
            paramsInheritanceStrategy: 'always',
        }),

        TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: FakeMissingTranslationHandler,
            },
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        BsSharedMenuComponent,
        BsSharedDialogStackComponent,
        BsSharedPartMarginalComponent,
        BsSharedPartBodyComponent,
    ],
    providers: [
        provideHttpClient(),

        BsCoreAuthenticatedGuard,
        BsCoreGuestGuard,

        {
            provide: 'env',
            useValue: environment
        },
        BsCoreRpcService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
})
export class AppModule {
}
